<template>
  <v-dialog v-model="showingSchoolInstrumentModal" fullscreen hide-overlay>
    <v-card>
      <v-app-bar fixed class="elevation-0 instrument-app-bar">
        <div>
          <v-app-bar-title class="font-weight-medium mt-2">
            <v-btn style="padding: 0 15px" @click="editNameModal = true" text class="evaluation_period">
              {{ evaluation.name ? (evaluation.name.length <= 25 ? evaluation.name : evaluation.name.substring(0, 22) + '...') : ' - ' }}
            </v-btn>
          </v-app-bar-title>
          <small style="padding: 0 15px">{{instrumentParser[instrument.name] }}</small>
        </div>
        <v-spacer></v-spacer>
        <span v-if="isEvaluation" >{{$t('Escuela')}}: {{ evaluation.school }}</span>
        <v-spacer></v-spacer>
        <div>
          <v-btn text v-if="isEvaluation" @click="editPeriodModal = true" class="evaluation_period">{{$t('Período')}}: {{ evaluation.period || '-' }}</v-btn>
          <v-btn text v-if="userRole === 0" @click="editDateModal = true" class="evaluation_period">
            <small class="ms-3">({{ $t('Date') }}: <small>{{ evaluation.date }})</small></small>
          </v-btn>
          <small v-else class="ms-3">({{ $t('Date') }}: <small>{{ evaluation.date }})</small></small>
        </div>
        <v-spacer></v-spacer>
        <v-menu offset-y left v-if="userRole === 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn ref="openDeleteMenu" text color="error" class="me-3" @keyup="handleMenuFocus($event, 'open')" :aria-label="$t('Eliminar evaluación')" v-on="on" v-bind="attrs">
              <span>{{ $t('Eliminar') }}</span>
              <v-icon class="ms-2">{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>
          </template>

          <v-card :color="$vuetify.theme.dark ? '#373258' : '#f6faff'" elevation="8">
            <v-card-subtitle> <b> {{ $t('¿Estás seguro de eliminar esta evaluación') + '?' }}</b></v-card-subtitle>
            <v-card-text class="text-center">

              <v-btn
                ref="closeDeleteMenu"
                small
                outlined
                color="tertiary"
                class="me-3"
                @keyup="handleMenuFocus($event, 'close')"
              >
                {{ $t('Cancelar') }}
              </v-btn>
              <v-btn
                small
                color="error"
                :outlined="$vuetify.theme.dark"
                @click="deleteEvaluation()"
              >
                {{ $t('Eliminar') }}
              </v-btn>

            </v-card-text>
          </v-card>
        </v-menu>
        <v-btn icon large color="tertiary" @click="close()" :title="$t('Close modal')"  :aria-label="$t('Close modal')">
          <v-icon large>{{icons.mdiClose}}</v-icon>
        </v-btn>
      </v-app-bar>
      <v-divider class="mb-16"></v-divider>

      <div v-for="(items, id) in tableItems" :key="`item_table_${id}`" >
        <v-data-table
          :headers="tableColumns"
          :items="items"
          :loading="loading"
          :hide-default-footer="true"
          :divider="true"
        >
        <template v-slot:top v-if="items[0]">
          <v-toolbar flat>
            <v-toolbar-title class="font-weight-medium ">{{ items[0].level === 0 ? items[0].category + ' - ' + preschoolCategoryParser[items[0].category] : items[0].level === 1 ? items[0].category + ' - ' + schoolCategoryParser[items[0].category] : ''}}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider v-if="isEvaluation"></v-divider>

          <div class="d-flex justify-space-between" v-if="isEvaluation">
            <ul style="list-style-type:none;" class="align-self-center">
              <li v-for="(el, idx) in getFileName(items[0].category_id)" :key="`el_${idx}_${items[0].category_id}`">
                <v-btn x-small icon color="primary" @click="downloadFile(el)"> <v-icon> {{ resolveFileIcon(el.name) }} </v-icon></v-btn>
                <span> <small>{{ el.name ? el.name : '-' }}</small></span>
              </li>
              <li v-if="!getFileName(items[0].category_id).length">- {{$t('No hay archivos cargados')}} </li>
            </ul>
            <div class="align-self-end">
              <v-btn class="my-3 me-3"
                outlined
                v-if="isEvaluation"
                @click="openFilesModal(items[0].category_id)"
                color="tertiary no-uppercase"
              >
                <v-icon
                  class="me-2"
                  color="tertiary"
                  small
                >
                  {{ icons.mdiPaperclip }}
                </v-icon>
                {{`${$t('Files')}: ${countFiles(items[0].category_id)}`}}
              </v-btn>
            </div>
          </div>
          <div v-if="getFileName(items[0].category_id).length" class="font-italic text-caption ms-2"> {{'* ' + $t('Descarga clickeando en los iconos de los archivos')}}
            <v-icon x-small icon color="primary">{{ icons.mdiPaperclip }}</v-icon> /
            <v-icon x-small icon color="primary">{{ fileIcons.jpg }}</v-icon> /
            <v-icon x-small icon color="primary">{{ fileIcons.xls }}</v-icon>
          </div>
        </template>

        <template #[`item.indicator`]="{item}">
          <div class="font-weight-regular ">
            {{ item.level === 0 ? item.indicator + ' - ' + preschoolIndicatorParser[item.indicator] : item.indicator + ' - ' + schoolIndicatorParser[item.indicator] }}
          </div>
        </template>

        <template #[`item.A`]="{item}">
          <div v-if="!isEvaluation && !isEdit">
            <v-icon
              class="me-2"
              color="success"
              v-if="item.principles.includes('A')"
            >
              {{ icons.mdiCheckCircleOutline }}
            </v-icon>

            <v-icon
              class="me-2"
              color="error"
              v-else
            >
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </div>
          <div v-else-if="isEvaluation && !isEdit">
            <v-select
              :aria-label="`Select a ${$t('Prácticas-autonomía')}`"
              v-if="item.principles.includes('A')"
              hide-details="true"
              :items="selectOptions"
              v-model="item.A"
              item-text="name"
              item-value="id"
              @change="setResult(item, 'A')"
            >
              <template v-slot:selection="data">
                {{ optionsParser[data.item.name] }}
              </template>

              <template v-slot:item="data">
                {{ optionsParser[data.item.name] }}
              </template>
            </v-select>
          </div>
          <div v-else-if="!isEvaluation && isEdit">
            <v-text-field
              type="number"
              :label="$t('Peso teórico')"
              v-model="item.A"
              v-if="item.principles.includes('A')"
            ></v-text-field>

            <v-icon
              class="me-2"
              color="error"
              v-else
            >
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </div>
        </template>

        <template #[`item.B`]="{item}">
          <div v-if="!isEvaluation && !isEdit">
            <v-icon
              class="me-2"
              color="success"
              v-if="item.principles.includes('B')"
            >
              {{ icons.mdiCheckCircleOutline }}
            </v-icon>

            <v-icon
              class="me-2"
              color="error"
              v-else
            >
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </div>
          <div v-else-if="isEvaluation && !isEdit">
            <v-select
              v-if="item.principles.includes('B')"
              :aria-label="`Select a ${$t('Trabajo colaborativo')}`"
              hide-details="true"
              :items="selectOptions"
              v-model="item.B"
              item-text="name"
              item-value="id"
              @change="setResult(item, 'B')"
            >
              <template v-slot:selection="data">
                {{ optionsParser[data.item.name] }}
              </template>

              <template v-slot:item="data">
                {{ optionsParser[data.item.name] }}
              </template>
            </v-select>
          </div>
          <div v-else-if="!isEvaluation && isEdit">
            <v-text-field
              type="number"
              :label="$t('Peso teórico')"
              v-model="item.B"
              v-if="item.principles.includes('B')"
            ></v-text-field>

            <v-icon
              class="me-2"
              color="error"
              v-else
            >
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </div>
        </template>

        <template #[`item.C`]="{item}">
          <div v-if="!isEvaluation && !isEdit">
            <v-icon
              class="me-2"
              color="success"
              v-if="item.principles.includes('C')"
            >
              {{ icons.mdiCheckCircleOutline }}
            </v-icon>

            <v-icon
              class="me-2"
              color="error"
              v-else
            >
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </div>
          <div v-else-if="isEvaluation && !isEdit">
            <v-select
              v-if="item.principles.includes('C')"
              :aria-label="`Select a ${$t('Comunicación transversal')}`"
              hide-details="true"
              :items="selectOptions"
              v-model="item.C"
              item-text="name"
              item-value="id"
              @change="setResult(item, 'C')"
            >
              <template v-slot:selection="data">
                {{ optionsParser[data.item.name] }}
              </template>

              <template v-slot:item="data">
                {{ optionsParser[data.item.name] }}
              </template>
            </v-select>
          </div>
          <div v-else-if="!isEvaluation && isEdit">
            <v-text-field
              type="number"
              :label="$t('Peso teórico')"
              v-model="item.C"
              v-if="item.principles.includes('C')"
            ></v-text-field>

            <v-icon
              class="me-2"
              color="error"
              v-else
            >
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </div>
        </template>

        <template #[`item.D`]="{item}">
          <div v-if="!isEvaluation && !isEdit">
            <v-icon
              class="me-2"
              color="success"
              v-if="item.principles.includes('D')"
            >
              {{ icons.mdiCheckCircleOutline }}
            </v-icon>

            <v-icon
              class="me-2"
              color="error"
              v-else
            >
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </div>
          <div v-else-if="isEvaluation && !isEdit">
            <v-select
              v-if="item.principles.includes('D')"
              :aria-label="`Select a ${$t('Inclusión educativa')}`"
              hide-details="true"
              :items="selectOptions"
              v-model="item.D"
              item-text="name"
              item-value="id"
              @change="setResult(item, 'D')"
            >
              <template v-slot:selection="data">
                {{ optionsParser[data.item.name] }}
              </template>

              <template v-slot:item="data">
                {{ optionsParser[data.item.name] }}
              </template>
            </v-select>
          </div>
          <div v-else-if="!isEvaluation && isEdit">
            <v-text-field
              type="number"
              :label="$t('Peso teórico')"
              v-model="item.D"
              v-if="item.principles.includes('D')"
            ></v-text-field>

            <v-icon
              class="me-2"
              color="error"
              v-else
            >
              {{ icons.mdiCloseCircleOutline }}
            </v-icon>
          </div>
        </template>

        <template #[`item.comment`]="{item}">
          <v-icon
            v-if="!isEvaluation"
            class="me-2"
            color="success"
          >
            {{ icons.mdiCheckCircleOutline }}
          </v-icon>

          <div align="end" class="d-flex flex-column" style="height: 100%;" v-if="isEvaluation && !isEdit">
            <div class="mb-2">
              <div v-for="(comment, idx) in findCommentsByIndicatorId(item.indicator_id)" :key="comment" style="text-align: left;">
                {{ truncateText(`${idx+1}- ${comment}`, 50)  }}
              </div>
            </div>
            <v-btn
              color="primary"
              class="no-uppercase mb-2 mt-auto ml-auto"
              small
              @click="openCommentsModal(item)"
            >
              <span>{{$t('Comentarios')}}</span>
            </v-btn>
          </div>
        </template>

        <template #[`item.action`]="{item}">
          <v-btn
            color="primary"
            small
            @click="updateIndiPrin(item)"
          >
            {{$t('Guardar')}}
          </v-btn>
        </template>

        </v-data-table>
        <v-divider></v-divider>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="tertiary" outlined @click="close()">
          {{$t('Cerrar')}}
        </v-btn>
      </v-card-actions>

      <files-modal v-if="isEvaluation" ref="files_modal"
        :filesToShow="filesToShow"
        :fileIcons="fileIcons"
        @add-file="addFile"
        @remove-file="removeFile"
        @reload-evaluation="getEvaluation()"
        :evaluation_id="evaluation.id"
      />

      <comments-modal v-if="isEvaluation" ref="comments_modal"
        :commentsToShow="commentsToShow"
        @add-comment="addComment"
        @remove-comment="removeComment"
        @edit-comment="editComment"
        @reload-evaluation="getEvaluation()"
        :evaluation_id="evaluation.id"
      />

      <template>
        <v-dialog v-model="editPeriodModal" max-width="500" @click:outside="closeEditPeriodModal()">
          <v-card class="pa-3">
            <v-card-title class="d-flex justify-center">
                {{`${$t('Editar')} ${($t('Período').toLowerCase())}` }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-text-field
                v-model="editingPeriod"
                >
                </v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pb-0">
              <v-spacer></v-spacer>
              <v-btn color="tertiary" text @click="closeEditPeriodModal()">
                {{ $t('Cerrar') }}
              </v-btn>
              <v-btn color="primary" @click="changePeriod()">
                  {{ $t('Editar') }}
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template>
        <v-dialog v-model="editDateModal" max-width="500" @click:outside="closeEditDateModal()">
          <v-card class="pa-3">
            <v-card-title class="d-flex justify-center">
                {{`${$t('Editar')} ${($t('Date').toLowerCase())}` }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editingDateFormatted"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editingDate"
                  no-title
                  scrollable
                  @input="dateMenu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pb-0">
              <v-spacer></v-spacer>
              <v-btn color="tertiary" text @click="closeEditDateModal()">
                {{ $t('Cerrar') }}
              </v-btn>
              <v-btn color="primary" @click="changeEvaluationDate()">
                  {{ $t('Editar') }}
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <!-- Show complete name Text -->
      <template>
        <v-dialog v-model="editNameModal" max-width="500" @click:outside="closeEditNameModal()">
          <v-card class="pa-3">
            <v-card-title class="d-flex justify-center">
                {{`${$t('Editar')} ${($t('Título').toLowerCase())}` }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-text-field
                v-model="editingName"
                type="text"
                dense
                hide-details
                >
                </v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pb-0">
              <v-spacer></v-spacer>
              <v-btn color="tertiary" text @click="closeEditNameModal()">
                {{ $t('Cerrar') }}
              </v-btn>
              <v-btn color="primary" @click="changeName()">
                  {{ $t('Editar') }}
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <alert-pop-up ref="alert_pop_up"/>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import AlertPopUp from '@/components/AlertPopUp.vue';
import CommentsModal from '@/components/Evaluations/CommentsModal'
import FilesModal from '@/components/Evaluations/FilesModal'
import instrumentsParserMixin from '@/utils/instrumentsParserMixin';
import {
  mdiClose,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiDeleteOutline,
  mdiMessageReplyTextOutline,
  mdiPaperclip,
  mdiFilePdfBox,
  mdiFileImageOutline,
  mdiFileGifBox,
  mdiFilePowerpoint,
  mdiFileWordBox,
  mdiFileExcel,
  mdiFileTableBox,
  mdiZipBox,
} from '@mdi/js'
export default {
  data: () => {
    return {
      showingSchoolInstrumentModal: false,
      instrument: {},
      evaluation: {},
      selectOptions: [],
      filesToShow: [],
      currentFileCategory: null,
      commentsToShow: [],
      currentCommentIndicator: null,
      editPeriodModal: false,
      editingPeriod: '',
      editDateModal: false,
      editingDate:'',
      editNameModal: false,
      editingName: '',
      dateMenu: false,
    }
  },
  mixins: [instrumentsParserMixin],
  methods: {
    async open(instrument, isEdit, evaluation) {
      document.body.className = 'waiting'
      this.loading = true
      if(evaluation) {
        this.isEvaluation = true
        this.evaluation = evaluation
        this.editingPeriod = evaluation.period
        this.editingName = evaluation.name
        this.editingDate = this.parseForDatePicker(evaluation.date)
        this.getOptionswithScores()
      }
      this.isEdit = isEdit
      this.instrument = instrument
      try {
        const response = await this.$api.getInstrument(instrument.id)

        this.tableItems = response.categories.map(category => {
          let items = []
          category.indicators.forEach(indicator => {
            let result = {
              indicator: indicator.name,
              indicator_id: indicator.id,
              principles: [],
              indi_prin: [],
              A:'',
              B:'',
              C:'',
              D:'',
              files: [],
              category: category.name,
              category_id: category.id,
              level: response.level
            }
            response.indicator_principle.forEach(el => {
              if(el.indicator__name === indicator.name) {
                result.principles.push(el.principle__name)
                result.indi_prin.push(el)
                if(isEdit) {
                  result[el.principle__name] = el.theoretical_weight
                }
                if(this.isEvaluation) {
                  this.evaluation.results.forEach(score => {
                    if(score.indicator_principle__id === el.id) {
                      result[el.principle__name] = score.options__id
                    }
                  })
                }
              }
            })
            items.push(result)
          })
          return items
        })
        this.showingSchoolInstrumentModal = true
      } catch(e) {
        console.log(e)
      } finally {
        document.querySelector('body').classList.remove('waiting');
        this.loading = false
      }
    },
    close() {
      this.showingSchoolInstrumentModal = false
      this.$emit('reload-evaluation-list')
      this.tableItems = []
    },
    async deleteEvaluation() {
      try {
        await this.$api.deleteEvaluation(this.evaluation.id);
        this.$emit('reload-evaluation-list')
        this.close()
      } catch (e) {
        console.log(e)
      }
    },
    handleMenuFocus(e, handler) {
      e.preventDefault();
      if (e.key === 'Enter' || e.keyCode === 13) {
        handler === 'open'
          ? this.$refs.closeDeleteMenu.$el.focus()
          : this.$refs.openDeleteMenu.$el.focus()
      }
    },
    async getOptionswithScores() {
      const response = await this.$api.getOptionswithScores();

      // Creamos la opción para el campo vacío
      const emptyOption = {
        id: -1,
        name: " - ",
        score: -1
      };

      this.selectOptions = [emptyOption, ...response.results];
    },
    async updateIndiPrin(item) {
      try {
        let form = []
        item.principles.forEach(principle => {
          item.indi_prin.forEach(indiPrin => {
            if(indiPrin.indicator__name === item.indicator && indiPrin.principle__name === principle) {
              form.push({id: indiPrin.id, value: parseInt(item[principle])})
            }
          })
        })
        const response = await this.$api.updateIndiPrin(form)
        if(response.status === 200) {
          this.$refs.alert_pop_up.showPopUp({ message: this.$t('Puntuaciones guardadas con éxito'), type: 'success' })
        }
      } catch(e) {
        console.log(e)
        if(e?.response?.status === 400) {
            this.$refs.alert_pop_up.showPopUp({ message: this.$t('Las puntuaciones deben sumar 100'), type: 'error' })
          }
      }
    },
    async setResult(item, principle) {
      // console.log('seleccionado: ', item[principle]);
      try {
        let form = {
          options:item[principle],
          evaluation: this.evaluation.id,
          indicator_principle: null
        }
        item.indi_prin.forEach(indiPrin => {
          if(indiPrin.indicator__name === item.indicator && indiPrin.principle__name === principle) {
            form.indicator_principle = indiPrin.id
          }
        })
        let result = this.evaluation.results.find(e => e.indicator_principle__id === form.indicator_principle)
        let response = null
        if(result) {
          item[principle] === -1
          ? response = await this.$api.deleteResult(result.id)
          : response = await this.$api.updateResult(form, result.id)
        } else {
          if(item[principle] !== -1) {
            response = await this.$api.postResult(form)
            this.evaluation.results.push({
              indicator_principle__id: response.data.indicator_principle,
              options__name: response.data.options,
              options__id: this.optionsIDParser[response.data.options],
              options__score: response.data.option_score,
              id: response.data.id
            })

          }
        }

        //this.getEvaluation()
        
      } catch(e) {
        console.log(e)
      }
    },
    setFilesToShow() {
      this.filesToShow = this.evaluation.files_ids ? this.evaluation.files_ids.filter(el => el.category__id === this.currentFileCategory) : []
    },
    addFile(file) {
      file.data.forEach(el => {
        this.evaluation.files_ids.push({
          category__id: el.category_id,
          file:el.url,
          id:el.id,
          name:el.name
        })
      })
      this.setFilesToShow()
    },
    removeFile(id) {
      this.evaluation.files_ids = this.evaluation.files_ids.filter(el => el.id !== id)
      this.setFilesToShow()
    },
    openFilesModal(item_category) {
      this.currentFileCategory = item_category
      this.setFilesToShow()
      this.$refs.files_modal.openFilesModal(item_category)
    },
    setCommentsToShow() {
      this.commentsToShow = this.evaluation.comments_ids.filter(el => el.indicator__id === this.currentCommentIndicator)
    },
    addComment(comment) {
        this.evaluation.comments_ids.push({
          indicator__id: comment.data.indicator,
          id:comment.data.id,
          comment:comment.data.comment
        })
      this.setCommentsToShow()
    },
    removeComment(id) {
      this.evaluation.comments_ids = this.evaluation.comments_ids.filter(el => el.id !== id)
      this.setCommentsToShow()
    },
    editComment(comment) {
      this.evaluation.comments_ids[this.evaluation.comments_ids.findIndex(x => x.id === comment.data.id)] = {
        indicator__id: comment.data.indicator,
        id:comment.data.id,
        comment:comment.data.comment
      }

      this.setCommentsToShow()
    },
    findCommentsByIndicatorId(indicatorId) {
      const comments = this.evaluation.comments_ids.filter(comment => comment.indicator__id === indicatorId);
      return comments.map(comment => comment.comment);
    },
    countFiles(categoryId){
      const filesByCategory = this.evaluation.files_ids ? this.evaluation.files_ids.filter(file => file.category__id === categoryId) : []
      return filesByCategory.length || 0
    },
    getFileName(categoryId){
      const filesByCategory = this.evaluation.files_ids ? this.evaluation.files_ids.filter(file => file.category__id === categoryId) : []
      return filesByCategory || '-'
    },
    resolveFileIcon(fileName) {
      const extension = fileName.split('.').pop();
      return  this.fileIcons[extension] || this.icons.mdiPaperclip;
    },
    async downloadFile(file_data) {
      try {
        const response = await this.$api.downloadFile(file_data.id)
        if(response.data.url) {
          window.open(response.data.url, '_blank')
        } else {
          const blob = new Blob([response.data]);
          const url = URL.createObjectURL(blob);
          // window.open(url, '_blank')
          const a = document.createElement('a');
          a.href = url;
          a.target = '_blank';
          a.download = file_data.name;
          a.click();
          URL.revokeObjectURL(url);
        }
      } catch(e) {
        console.log(e.response)
        if(e?.response?.status === 403) {
          this.$refs.alert_pop_up.showPopUp({ message: this.$t(e?.response?.data), type: 'error' })
        }
      }
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + ' ...';
      } else {
        return text;
      }
    },
    openCommentsModal(item) {
      this.currentCommentIndicator = item.indicator_id
      this.setCommentsToShow()
      this.$refs.comments_modal.openCommentsModal(item)
    },
    async getEvaluation() {
      const response = await this.$api.getEvaluation(this.evaluation.id)
      this.evaluation = response
    },
    closeEditPeriodModal() {
      this.editPeriodModal = false
      this.$nextTick(function () {
        document.documentElement.classList.add('overflow-y-hidden');
      })
    },

    closeEditDateModal() {
      this.editDateModal = false
      this.editingDate = this.parseForDatePicker(this.evaluation.date)
      this.$nextTick(function () {
        document.documentElement.classList.add('overflow-y-hidden');
      })
    },

    closeEditNameModal() {
      this.editNameModal = false
      this.$nextTick(function () {
        document.documentElement.classList.add('overflow-y-hidden');
      })
    },

    async changePeriod() {
      const form = {
        period: this.editingPeriod,
        instrument: this.evaluation.instrument_id,
        school: this.evaluation.school_id
      }
      try {
        const response = await this.$api.changePeriod(this.evaluation.id, form)
        this.evaluation.period = this.editingPeriod
        this.$emit('reload-evaluation-list')
        this.closeEditPeriodModal()
      } catch (error) {
        console.log(error);
      }
    },

    async changeEvaluationDate() {
      const form = {
        date: this.editingDate,
        instrument: this.evaluation.instrument_id,
        school: this.evaluation.school_id
      }
      try {
        const response = await this.$api.changeEvaluationDate(this.evaluation.id, form)
        this.evaluation.date = this.formatDate(this.editingDate)
        this.$emit('reload-evaluation-list')
        this.closeEditDateModal()
      } catch (error) {
        console.log(error);
      }
    },

    parseForDatePicker(date) {
      if (!date) return ''

      const [day, month, year] = date.split('-')
      return `${year}-${month}-${day}`
    },

    formatDate(date) {
      if (!date) return ''

      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    },

    async changeName() {
      const form = {
        name: this.editingName,
        instrument: this.evaluation.instrument_id,
        school: this.evaluation.school_id
      }
      try {
        const response = await this.$api.changePeriod(this.evaluation.id, form)
        this.evaluation.name = this.editingName
        this.$emit('reload-evaluation-list')
        this.closeEditNameModal()
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {
    AlertPopUp,
    CommentsModal,
    FilesModal
  },
  computed: {
    userRole() { return this.$store.getters['session/getUserRole']() },
    tableColumns() {
      return [
        { text: this.$t('Indicadores'), value: 'indicator', align: 'start', sortable: false,width: '25%', divider: true,},
        { text: this.$t('Prácticas-autonomía'), value: 'A', align: 'center', sortable: false, divider: true,},
        { text: this.$t('Trabajo colaborativo'), value: 'B', align: 'center', sortable: false, divider: true,},
        { text: this.$t('Comunicación transversal'), value: 'C', align: 'center', sortable: false, divider: true,},
        { text: this.$t('Inclusión educativa'), value: 'D', align: 'center', sortable: false, divider: true,},
        this.isEdit ? {text: '-', value: 'action', align: 'center', sortable: false, divider: true,} :
        { text: this.$t('Comentarios'), value: 'comment', align: 'center', sortable: false,width: '25%', divider: true,},
      ]
    },
    editingDateFormatted () {
        return this.formatDate(this.editingDate)
      },
    optionsParser() {
      const parser = {
        "A": this.$t('No aplica'),
        "B": this.$t('No se encuentra evidencia'),
        "C": this.$t('Focal'),
        "D": this.$t('En expansión'),
        "E": this.$t('Transversal'),
        "F": this.$t('Política institucional'),
        "G": this.$t('Cultura innovadora'),
      }
      return parser
    },

    optionsIDParser() {
      const parser = {
        "A": 1,
        "B": 2,
        "C": 3,
        "D": 4,
        "E": 5,
        "F": 6,
        "G": 7,
      }
      return parser
    },

    instrumentParser() {
    const parser = {
      School_instrument: this.$t('Instrumento Escolar'),
      Pre_school_instrument: this.$t('Instrumento Preescolar')
    }
    return parser
  }
  },
  watch: {
    evaluation(newValue) {
      if(newValue) {
        this.setCommentsToShow()
        this.setFilesToShow()
      }
    }
  },
  setup() {
    const isEvaluation = ref(false)
    const isEdit = ref(false)
    const tableItems = []
    const loading = ref(false)

    const fileIcons = {
      xls: mdiFileExcel,
      xlsx: mdiFileExcel,
      csv: mdiFileTableBox,
      jpg: mdiFileImageOutline,
      jpeg: mdiFileImageOutline,
      png: mdiFileImageOutline,
      pdf: mdiFilePdfBox,
      ppt: mdiFilePowerpoint,
      pptx: mdiFilePowerpoint,
      doc: mdiFileWordBox,
      docx: mdiFileWordBox,
      gif: mdiFileGifBox,
      zip: mdiZipBox,
      rar: mdiZipBox,
      gz: mdiZipBox,
      // Agrega más extensiones y sus iconos
    };

    return {
      isEvaluation,
      tableItems,
      loading,
      isEdit,
      icons: {
        mdiClose,
        mdiCloseCircleOutline,
        mdiCheckCircleOutline,
        mdiDeleteOutline,
        mdiMessageReplyTextOutline,
        mdiPaperclip,
      },
      fileIcons
    }
  }
}
</script>

<style scoped>

body.waiting * {
  cursor: wait !important;
}

.instrument-app-bar {
  border-bottom: solid 1px rgba(94, 86, 105, 0.14) !important;
  height: 66px !important;
}

.no-uppercase {
  text-transform: unset !important;
}

tbody tr td {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.evaluation_period {
  text-transform: none !important;
}

</style>
