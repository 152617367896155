<template>
  <v-dialog v-model="showingFilesModal" width="800">
    <v-card>
      <div class="d-flex justify-space-between">
        <v-card-title>{{$t('Files')}}</v-card-title>
        <v-btn icon color="primary" @click="closeFilesModal()">
          <v-icon>{{icons.mdiClose}}</v-icon>
        </v-btn>
      </div>
      <v-card-text :class=" filesToShow.length ? 'pb-0' : ''">
        <div class="d-flex">
          <v-col cols="9">
            <v-file-input
            v-model="fileToUpload"
            multiple
            class=""
            webkitRelativePath
            accept=".jpg, .jpeg, .png, .pdf, .xlsx, .xls, .csv, .ppt, .pptx, .mp4"
            ref="upload_file_input"
            :label="$t('Seleccione un archivo')+'...'"
            ></v-file-input>
          </v-col>
          <v-col>
            <v-btn color="primary" large @click="uploadFile()" :disabled="!fileToUpload || fileToUpload.length <=0 || btnLoading">
              <v-icon v-if="!btnLoading" class="me-2">{{ icons.mdiCheck}}</v-icon>
              <v-progress-circular
              v-if="btnLoading"
              class="me-2"
              indeterminate
              :size="24"
              :width="2"
              color="secondary"
              ></v-progress-circular>
              {{ $t('Upload') }}
            </v-btn>
          </v-col>
        </div>
        <v-row class="justify-center">
          <v-col cols="10" class="d-flex flex-wrap grey-border-top" v-if="filesToShow.length">
            <v-chip v-for="file in filesToShow" class="ma-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="downloadFile(file)" x-small icon color="primary" v-on="on" v-bind="attrs"><v-icon>{{ resolveFileIcon(file.name) }}</v-icon></v-btn>
                </template>
                <span>{{$t('Descargar')}}</span>
              </v-tooltip>
              <span class="ms-1 me-2"> {{ file.name }}</span>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small icon color="error" v-on="on" v-bind="attrs"><v-icon>{{ icons.mdiClose }}</v-icon></v-btn>
                  </template>
                  <v-card>
                    <v-card-subtitle> <b>{{$t('¿Eliminar el archivo:')}} <span class="text-decoration-underline">{{file.name}}</span>? </b></v-card-subtitle>
                    <v-card-text class="text-center">
                      <v-btn 
                        small 
                        color="tertiary" 
                        outlined  
                        class="me-3"
                      >
                        {{$t('Cancelar')}}
                      </v-btn>
                      <v-btn small color="error" @click="removeFile(file.id)">{{$t('Eliminar')}}</v-btn>
                    </v-card-text>
                  </v-card>
                </v-menu>
            </v-chip>
          </v-col>
        </v-row>
        <div v-if="filesToShow.length" class="font-italic pt-3"> {{'* ' + $t('Descarga clickeando en los iconos de los archivos')}}
          <v-icon x-small icon color="primary">{{ icons.mdiPaperclip }}</v-icon> /
          <v-icon x-small icon color="primary">{{ fileIcons.jpg }}</v-icon> /
          <v-icon x-small icon color="primary">{{ fileIcons.xls }}</v-icon>
         </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <div class="d-flex flex-column pr-3 mr-10">
          <span style="font-size: 0.9rem;">
            {{$t('El archivo que se desea cargar debe tener alguna de las siguientes extensiones y tamaños máximos')}}: 
          </span>
  
          <span style="font-size: 0.9rem;">
            25 MB {{$t('para')}} videos (mp4)
          </span>
          <span style="font-size: 0.9rem;">
            10 MB {{$t('para')}} Powerpoint (ppt {{$t('y')}} pptx)
          </span>
          <span style="font-size: 0.9rem;">
            10 MB  {{$t('para')}} PDF, xls, xlsx {{$t('y')}} csv
          </span>
          <span style="font-size: 0.9rem;">
            6 MB {{$t('para')}} JPG, PNG {{$t('y')}} JPEG
          </span>
        </div>

        <v-spacer></v-spacer>
        <v-btn 
          color="tertiary" 
          outlined 
          @click="closeFilesModal()">
          {{$t('Cancelar')}}
        </v-btn>
      </v-card-actions>
      <alert-pop-up ref="alert_pop_up"/>
    </v-card>
  </v-dialog>
</template>

<script>
import AlertPopUp from '@/components/AlertPopUp.vue';

import {
  mdiClose,
  mdiDownload,
  mdiCheck,
  mdiPaperclip
} from '@mdi/js'
export default {
  data: () => {
    return {
      showingFilesModal: false,
      fileToUpload: null,
      category_id: null,
      btnLoading: false,
    }
  },
  methods: {
    closeFilesModal() {
      this.$refs.upload_file_input.reset()
      this.showingFilesModal = false
    },
    openFilesModal(item_category) {
      this.category_id = item_category
      this.showingFilesModal = true
    },
    resolveFileIcon(fileName) {
      const extension = fileName.split('.').pop();
      return  this.fileIcons[extension] || this.icons.mdiPaperclip;
    },
    async uploadFile() {
      this.btnLoading = true
      try {
        const formData = new FormData()

        if (this.fileToUpload){
        for (let i = 0; i < this.fileToUpload.length; i++) {
            formData.append('files', this.fileToUpload[i])
          }
        }
        // formData.append('file', this.fileToUpload)
        formData.append('evaluation', parseInt(this.evaluation_id))
        formData.append('category', parseInt(this.category_id))
        formData.append('user', this.userData.user_id)

        const response = await this.$api.uploadFile(formData)
        this.$refs.upload_file_input.reset()
        this.$emit('add-file', response)
      } catch(e) {
        if(e.response?.status === 500) {
          this.$refs.alert_pop_up.showPopUp({ message: this.$t('Nombre del archivo demasiado largo'), type: 'error' })
        }
        if(e.response?.status === 400) {
          e.response?.data?.files.length 
          ? this.$refs.alert_pop_up.showPopUp({ message: this.$t(e.response?.data?.files[0]), type: 'error' }) 
          : this.$refs.alert_pop_up.showPopUp({ message: this.$t('Ha ocurrido un error, intente nuevamente más tarde'), type: 'error' })
        }

        if(e.message === 'Network Error') {
          this.$refs.alert_pop_up.showPopUp({ message: this.$t('Ha ocurrido un error, intente nuevamente más tarde'), type: 'error' }) 
        }
      } finally {
        this.btnLoading = false
      }
    },
    async removeFile(id) {
      try {
        const response = await this.$api.removeFile(id)
        this.$emit('remove-file', id)
      } catch(e) {
        console.log(e.response)
        if(e?.response?.status === 403) {
          this.$refs.alert_pop_up.showPopUp({ message: this.$t(e?.response?.data), type: 'error' })
        }
      }
    },

    async downloadFile(file_data) {
      try {
        const response = await this.$api.downloadFile(file_data.id)
        console.log(response);
        if(response.data.url) {
          window.open(response.data.url, '_blank')
        } else {
          const blob = new Blob([response.data]);
          const url = URL.createObjectURL(blob);
          // window.open(url, '_blank')
          const a = document.createElement('a');
          a.href = url;
          a.target = '_blank';
          a.download = file_data.name;
          a.click();
          URL.revokeObjectURL(url);
        }
      } catch(e) {
        console.log(e)
        if(e?.response?.status === 403) {
          this.$refs.alert_pop_up.showPopUp({ message: this.$t(e?.response?.data), type: 'error' })
        }
      }
    },

    createLinkClicked(data) {
      const a = document.createElement("a");
      a.href = data.url;
      // a.setAttribute('download', data.name)
      a.download = data.name
      a.target = "_blank";
      a.click();
    },
  },

  computed: {
    userData() { return this.$store.getters['session/userData']() },
    // acceptedFiles() { return process.env.VUE_APP_ACCEPTED_FILES },
  },
  props: {
    filesToShow: {
      type: Array,
      default: []
    },
    fileIcons: {
      type: Object,
      default: {}
    },
    evaluation_id: {
      type: Number,
      required: true
    }
  },
  components: {
    AlertPopUp
  },
  setup() {
    return {
      icons: {
        mdiClose,
        mdiDownload,
        mdiCheck,
        mdiPaperclip
      }
    }
  }
}
</script>
