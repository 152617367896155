<template>
  <v-dialog v-model="showingCommentsModal" width="800">
    <v-card>
      <div class="d-flex justify-space-between">
        <v-card-title>{{$t('Comentarios')}}</v-card-title>
        <v-btn icon @click="closeCommentsModal()" aria-label="close modal">
          <v-icon>{{icons.mdiClose}}</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <div class="d-flex">
          <v-textarea
            class="me-6"
            ref="comment_textarea"
            rows="2"
            outlined
            @keyup="debounceCommentToAdd"
            counter="1000"
            validate-on-blur
            :rules="[v => v && v.length >= 3 || $t('Ingrese un mínimo de 3 caracteres'), v => v && v.length <= 1000 || $t('Demasiados caracteres')]"
          ></v-textarea>
          <v-btn color="primary" @click="addComment()" :disabled="commentToAdd.length < 3">
            <v-icon class="me-2">{{icons.mdiMessageReplyTextOutline}}</v-icon>
            {{ $t('Agregar') }}
          </v-btn>
        </div>
        <div v-if="commentsToShow.length">
          <v-list flat>
              <v-list-item v-for="(comment, idx) in commentsToShow" :key="`comment_${idx}`">
                <v-list-item-content>
                  <v-btn
                    text
                    class="no-uppercase justify-start"
                    @click="showFullComment(comment)"
                  >{{ `${idx + 1} - ${comment.comment}` }}</v-btn>
                </v-list-item-content>
                <v-list-item-action>

                  <v-btn
                    class="me-2 icon-with-label"
                    color="error"
                    text
                    @click="removeComment(comment.id)"
                  >
                    <div class="d-flex align-center">
                      <span class="label">
                        {{$t('Eliminar')}}
                      </span>
                      <v-icon size="1.5rem">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </div>
                  </v-btn>

                  <v-btn
                    class="me-2 icon-with-label "
                    color="primary"
                    text
                    @click="showEditingComment(comment)"
                  >
                    <div class="d-flex align-center">
                      <span class="label">
                        {{$t('Editar')}}
                      </span>
                      <v-icon size="1.5rem">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </div>
                  </v-btn>


                </v-list-item-action>
              </v-list-item>
          </v-list>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="tertiary"
          outlined
          @click="closeCommentsModal()"
        >
          {{$t('Cancelar')}}
        </v-btn>
      </v-card-actions>
    </v-card>

    <template>
      <v-dialog v-model="showingFullCommentModal" max-width="500">
        <v-card class="pa-3">
          <v-card-title class="d-flex justify-center">
            <div v-if="fullComment.toEdit"> {{ 'Editar Comentario' }}</div>
            <div v-else>{{$t('Comentario completo')}}</div>
          </v-card-title>
          <v-divider></v-divider>
          <v-textarea v-if="fullComment.toEdit"
            v-model="fullComment.comment"
            class="mt-4"
            ref="full_comment_textarea"
            rows="2"
            outlined
            @keyup="debounceCommentToEdit"
            counter="1000"
            validate-on-blur
            :rules="[v => v && v.length >= 3 || $t('Ingrese un mínimo de 3 caracteres'), v => v && v.length <= 1000 || $t('Demasiados caracteres')]"
          ></v-textarea>
          <v-card-text v-else>
            {{ fullComment.comment }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn color="tertiary" text @click="showingFullCommentModal = false">
              {{$t('Cerrar')}}
            </v-btn>
            <v-btn color="primary" v-if="fullComment.toEdit"
            @click="editComment"
            :disabled=" fullComment && fullComment.comment.length < 3"
            >
              {{$t('Editar')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <alert-pop-up ref="alert_pop_up"/>

  </v-dialog>
</template>

<script>
import AlertPopUp from '@/components/AlertPopUp.vue';
import debounce from '../../plugins/debounce.js'


import {
  mdiDeleteOutline,
  mdiMessageReplyTextOutline,
  mdiClose
} from '@mdi/js'
export default {
  data:() => {
    return {
      showingCommentsModal: false,
      showingFullCommentModal: false,
      commentToAdd: '',
      indicator_id: null,
      fullComment: {}
    }
  },
  methods: {
    showFullComment(comment) {
      this.fullComment = comment
      this.showingFullCommentModal = true
    },
    closeCommentsModal() {
      console.log(this.$refs)
      this.$refs.comment_textarea.reset()
      this.showingCommentsModal = false
    },
    openCommentsModal(item) {
      this.indicator_id = item.indicator_id
      this.showingCommentsModal = true
    },
    debounceCommentToAdd: debounce(function(e) {
      this.commentToAdd = e.target.value
    }, 400),
    debounceCommentToEdit: debounce(function(e) {
      this.fullComment.comment = e.target.value
    }, 400),
    async addComment() {
      try {
        let form = {
          evaluation: this.evaluation_id,
          comment: this.commentToAdd,
          indicator: this.indicator_id
        }
        const response = await this.$api.addComment(form)
        this.$refs.comment_textarea.reset()
        this.commentToAdd = ''
        this.$emit('add-comment', response)
      } catch(e) {
        if(e?.response?.status === 400) {
          this.$refs.alert_pop_up.showPopUp({ message: this.$t(e?.response?.data?.comment[0]), type: 'error' })
          console.log(e?.response?.data?.comment[0])
        }
      }
    },
    async removeComment(id) {
      try {
        const response = await this.$api.removeComment(id)
        this.$emit('remove-comment', id)
      } catch(e) {
        console.log(e)
        if(e?.response?.status === 403) {
          this.$refs.alert_pop_up.showPopUp({ message: this.$t(e?.response?.data), type: 'error' })
        }
      }
    },
    async showEditingComment(comment) {
      this.fullComment = {...comment, toEdit: true}

      return this.showingFullCommentModal = true
    },
    async editComment() {
      const form = {
        comment: this.fullComment.comment
      }

      try {
        const response = await this.$api.editComment(this.fullComment.id,form)
        this.$emit('edit-comment', response)
        this.showingFullCommentModal = false
        this.fullComment = {}
      } catch(e) {
        console.log(e)
        if(e?.response?.status === 403) {
          this.$refs.alert_pop_up.showPopUp({ message: this.$t(e?.response?.data), type: 'error' })
        }
      }
    }
  },
  props: {
    commentsToShow: {
      type: Array,
      default: []
    },
    evaluation_id: {
      type: Number,
      required: true
    }
  },
  components: {
    AlertPopUp
  },
  setup() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiMessageReplyTextOutline,
        mdiClose
      }
    }
  }
}
</script>
